import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContentService } from '../content/content.service';
import { AppStateService } from '../app-state/app-state.service';
import { Observable, map, of, switchMap, tap } from 'rxjs';

type DialogParams = {
  panelClass: string;
  backdropClass: string;
  autoFocus?: boolean;
  closeOnNavigation?: boolean;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    public dialog: MatDialog,
    private contentService: ContentService,
    private appState: AppStateService,
  ) { }


  /**
   * @description Generic dialog opener with support for Contenful content
   * @param ref Reference of Dialog Component
   * @param content String of contentfull content collection items.
   * @param dialogParams Map of properties like `panelClass` and `backdropClass` and possible data you want to pass on to the dialog component
   * @returns Returns short lives observable value of MatDialogRef
   */
  showDialog<T>(ref: any, content: string[], dialogParams: DialogParams): Observable<MatDialogRef<T, any>> {
    if (content) {
      return this.contentService.getContent(content)
        .pipe(
          switchMap(() => {
            const keys = extractCollectionKeys();
            for (const key of keys) {
              const text = this.appState.contentItemCollection[key];
              if (text) {
                dialogParams.data = {
                  ...dialogParams.data,
                  ...text,
                }
                dialogParams.autoFocus = false;
                dialogParams.closeOnNavigation = true;
              }
            }
            return of(this.dialog.open<T>(ref, dialogParams))
          }))
    } else {
      return of(this.dialog.open<T>(ref, {
        ...dialogParams,
        autoFocus: false,
        closeOnNavigation: true,
      }));
    }

    function extractCollectionKeys() {
      // use Set to remove duplicate keys
      const collections = new Set(content.map(val => val.split(':').shift()));
      return Array.from(collections);
    }
  }
}
